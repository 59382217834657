<template>
    <BodyWrapper :disabled="disabled" :position="left ? 'left' : 'right'">
        <template #default>
            <BodyWrapper :disabled="disabled" :position="left ? 'left' : 'right'">
                <template #default>
                    <slot></slot>
                </template>
                <template #side>
                    <SidePanelNav :left="left"/>
                </template>
            </BodyWrapper>
        </template>
        <template #side>
            <OSizerPanel ref="sizerPanel" :id="sizerId" class="side-panel-sizer" expand collapse
                :width="width" :initiallyCollapsed="initiallyCollapsed"
                hideIcons>
                <OColContainer class="bg-body">
                    <SidePanelNav v-if="!left && sizerState === 'collapsedLeft'" skipCollapse/>
                    <div class="row-container flex-grow-1 min-sizes overflow-hidden side-panel-tabs-container">
                        <div class="min-h-unset hstack pt-2 ps-2" :class="headerClass">
                            <slot v-if="activeTab" :name="`tab-title(${activeTab})`">
                                <h5 class="mb-0 p-2">{{activeTabTitle}}</h5>
                                <button v-if="detailIframe && activeTab === 'details'" class="btn btn-link btn-sm" :title="$t('Reload detail')"
                                    @click="() => reloadDetailIframe($refs.detailTabRef)">
                                    <i class="bi bi-arrow-clockwise"></i>
                                </button>
                            </slot>
                        </div>
                        <div class="row-container h-100 w-100 px-1 mt-2 ps-2">
                            <OTabs ref="tabsRef" tabsClass="nav nav-underline" hideNavbar>
                                <OTab v-if="hasDetails" :id="tabId('details')" :title="detailTabTitle" active alwaysRender paneClass="position-relative">
                                    <div v-if="blockDetailsPointerEvents" class="position-absolute w-100 h-100"></div>
                                    <ODetailTab ref="detailTabRef" :iframeSrc="detailIframe" :detailTabTitle="detailTabTitle" :iframeEnabled="iframeEnabled"
                                        :blankMessage="detailTabEmptyMessage" />
                                </OTab>
                                <OTab v-for="tab in tabs" :id="tabId(tab.id)" :title="tab.title">
                                    <template #title>
                                        <i :class="tab.iconClass"></i>
                                        {{ tab.title }}
                                    </template>
                                    <slot :name="`tab-content(${tab.id})`"></slot>
                                </OTab>
                            </OTabs>
                        </div>
                        <div class="min-h-unset py-1 p-1 d-flex" :class="footerClass">
                            <div v-if="detailIframe" role="button" @click="$event => copyDetailUrl($event)" 
                                class="hstack o365-detail-link-container px-2 text-primary">
                                <span class="text-truncate" v-tooltip="{ title: detailIframeUrlCopyTooltip, tooltipRef: tooltip => detailIframeUrlCopyTooltipObj=tooltip }">
                                    {{detailIframeDisplayUrl}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <SidePanelNav v-if="left && sizerState === 'collapsedRight'" left skipCollapse/>
                </OColContainer>
            </OSizerPanel>
        </template>
    </BodyWrapper>
</template>

<script setup lang="ts">
import type { Component, FunctionalComponent } from 'vue';
// import OColContainer from './Containers.ColContainer.vue';
import OSizerPanel from './Sizer.SizerPanel.vue';
import SidePanelNav from './SidePanel.SidePanelNavTabs.vue';
import { InjectionKeys } from 'o365-utils';
import { OTabs, OTab, OColContainer } from 'o365-ui-components';
import { $t } from 'o365-utils';
import { h, ref, provide, toRef, computed, useSlots, onMounted } from 'vue';
import { useAsyncComponent, vTooltip } from 'o365-vue-utils';
import { localStorageHelper as localStorage } from 'o365-modules';
import { nextTick } from 'vue';

const ODetailTab = useAsyncComponent('SidePanelDetailsTab.vue', { importFn: () => import('./SidePanel.SidePanelDetailsTab.vue')});

type SidePanelTab = {
    id: string,
    title: string,
    iconClass: string,
};

const props = withDefaults(defineProps<{
    id?: string,
    disabled?: boolean,
    width?: string,
    initiallyCollapsed?: boolean
    headerClass?: string,
    footerClass?: string,
    tabs?: SidePanelTab[],
    detailIframe?: string,
    detailTabTitle?: string,
    detailTabEmptyMessage?: string,
    /**
     * When true will render an overlay ontop of the details tab. Can be used during drag events
     * to prevent the inner iframe from consuming the events
     */
    blockDetailsPointerEvents?: boolean,
}>(), {
    width: '400px',
    detailTabTitle: () => $t('Details')
});

const slots = useSlots();
const emit = defineEmits<{
    (e: 'switchSide'): void
}>();

const sizerPanel = ref<InstanceType<typeof OSizerPanel>|null>(null);
const sizerState = ref('');
const activeTab = ref('');
const tabs = toRef(props, 'tabs');
const tabsRef = ref(null);
let lastActiveTab = '';

const hasDetails = computed(() => !!(props.detailIframe || slots.detailTab));
const sizerId = computed(() => left.value ? `${props.id}-left` : `${props.id}-right`)
const activeTabTitle = computed(() => {
    if (activeTab.value === 'details') {
        return props.detailTabTitle;
    }
    const tab = tabs.value?.find(x => x.id === activeTab.value);
    return tab?.title;
});

let initiallyOnLeft = true;
try {
    const value = localStorage.getItem('sidepanel-position')
    initiallyOnLeft = value === 'true' || value === '';
} catch (ex) {
    initiallyOnLeft = true;
}

const left = ref(initiallyOnLeft);
const iframeEnabled = computed(() => {
    return sizerState.value !== (left.value ? 'collapsedLeft' : 'collapsedRight')  && activeTab.value === 'details';
});

provide(InjectionKeys.sidePaneComponentKey, {
    sizerState,
    activeTab,
    tabs,
    toggleTab,
    hasDetails,
    epxandSidePanel,
    expandComponent,
    switchSides
});
const uid = crypto.randomUUID();

function tabId(pId: string) {
    if (props.id) {
        return `${props.id}-tab-${pId}`
    } else {
        return `sidepanel-${uid}-tab-${pId}`
    }
} 

function reloadDetailIframe(pRef) {
    const iframe = pRef?.$el?.parentElement?.querySelector('iframe');
    iframe?.contentWindow.location.reload()
}

async function sizerOperation(pSide: 'left' | 'right') {
    const sizer = sizerPanel.value?.getSizer();
    if (sizer == null) { return; }

    sizer.addAnimationClasses();

    window.setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
    }, 301);
    if (pSide === 'left') {
        await sizer.left();
    } else {
        await sizer.right();
    }
    handleSizerCollapse();
}

function toggleTab(pId: string, pSkipCollapse?: boolean) {
    if (tabsRef.value == null) { return; }

    if (pSkipCollapse) {
        activeTab.value = pId;
    } else if (left.value) {
        if (activeTab.value === pId && sizerState.value !== 'collapsedLeft') {
            sizerOperation('left');
            activeTab.value = null;
        } else {
            if (sizerState.value === 'collapsedLeft') {
                sizerOperation('right');
            }
            activeTab.value = pId;
        }
    } else {
        if (activeTab.value === pId && sizerState.value !== 'collapsedRight') {
            sizerOperation('right');
            activeTab.value = null;
        } else {
            if (sizerState.value === 'collapsedRight') {
                sizerOperation('left');
            }
            activeTab.value = pId;
        }
    }

    if (activeTab.value) {
        lastActiveTab = activeTab.value;
    }
    tabsRef.value.setActiveTab(tabId(pId));
}

function handleSizerCollapse() {
    const sizerControl = sizerPanel.value?.getSizer();
    if (sizerControl == null) { return; }
    if (sizerControl.sizer.classList.contains('collapsedRight')) {
        sizerState.value = 'collapsedRight';
    } else if (sizerControl.sizer.classList.contains('collapsedLeft')) {
        sizerState.value = 'collapsedLeft';
    } else {
        sizerState.value = 'expanded';
    }
}

async function epxandSidePanel() {
    if (!left.value) {
        switch (sizerState.value) {
            case 'collapsedRight':
                await sizerOperation('left');
                await (new Promise(resolve => setTimeout(resolve, 300)));
                await sizerOperation('left');
                activeTab.value = lastActiveTab || (hasDetails.value ? 'details' : tabs.value[0]?.id);
                toggleTab(activeTab.value, true);
                break;
            case 'collapsedLeft':
                await sizerOperation('right');
                break;
            case 'expanded':
                await sizerOperation('left');
                break;
        }
    } else {
        switch (sizerState.value) {
            case 'collapsedLeft':
                await sizerOperation('right');
                await (new Promise(resolve => setTimeout(resolve, 300)));
                await sizerOperation('right');
                activeTab.value = lastActiveTab || (hasDetails.value ? 'details' : tabs.value[0]?.id);
                toggleTab(activeTab.value, true);
                break;
            case 'collapsedRight':
                await sizerOperation('left');
                break;
            case 'expanded':
                await sizerOperation('right');
                break;
        }
    }
}

async function expandComponent() {
    if (left.value) {
        switch (sizerState.value) {
            case 'collapsedRight':
                await sizerOperation('left');
                await sizerOperation('left');
                activeTab.value = null
                break;
            case 'collapsedLeft':
                await sizerOperation('right');
                activeTab.value = lastActiveTab || (hasDetails.value ? 'details' : tabs.value[0]?.id);
                toggleTab(activeTab.value, true);
                break;
            case 'expanded':
                await sizerOperation('left');
                activeTab.value = null;
                break;
        }
    } else {
        switch (sizerState.value) {
            case 'collapsedRight':
                await sizerOperation('left');
                activeTab.value = lastActiveTab || (hasDetails.value ? 'details' : tabs.value[0]?.id);
                toggleTab(activeTab.value, true);
                break;
            case 'collapsedLeft':
                await sizerOperation('right');
                await sizerOperation('right');
                activeTab.value = null;
                break;
            case 'expanded':
                await sizerOperation('right');
                activeTab.value = null;
                break;
        }
    }
}

const detailIframeUrlCopyTooltip = ref($t('Copy url to clipboard'));
const detailIframeDisplayUrl = computed(() => {
    if (props.detailIframe) {
        const url = new URL(props.detailIframe, window.location.origin);
        url.searchParams.delete('HideNav');
        url.searchParams.delete('message');
        return url.pathname + url.search;
    } else {
        return undefined;
    }
});
let detailIframeUrlCopyTooltipObj: any = null;
function copyDetailUrl(e) {
    const element = e.target?.closest('.o365-detail-link-container');
    if (element == null || !props.detailIframe) { return; }
    const url = new URL(props.detailIframe, window.location.origin);
    url.searchParams.delete('HideNav');
    url.searchParams.delete('message');
    const link = url.href;
    detailIframeUrlCopyTooltip.value = $t('Copied to clipboard');
    (detailIframeUrlCopyTooltipObj?._element as HTMLElement)?.addEventListener('hidden.bs.tooltip', () => {
        detailIframeUrlCopyTooltip.value = $t('Copy url to clipboard');
    }, { once: true });
    navigator.clipboard.writeText(link);
}

async function switchSides() {
    // const sizerControl = sizerPanel.value?.getSizer();
    // if (sizerControl) {
        // const state = this.sizerControl.getState();
        // this.sizerStateCache = state;
    // }
    localStorage.setItem('sidepanel-position', `${!left.value}`, { global: false});
    left.value = !left.value;
    emit('switchSide');
    nextTick().then(() => {
        toggleTab(activeTab.value, true);
    });
}

onMounted(() => {
    handleSizerCollapse();
    if (sizerState.value === 'expanded') {
        activeTab.value = lastActiveTab || (hasDetails.value ? 'details' : tabs.value[0]?.id);
        toggleTab(activeTab.value, true);
    }
});

type BodyWrapperProps = {
    disabled?: boolean,
    class?: string,
    position?: 'left' | 'right',
    is?: Component | string
};

const BodyWrapper: FunctionalComponent<BodyWrapperProps, {}, {
    default: any,
    side: any
}> = (pProps, pCtx) => {
    const Container: any = pProps.is ?? OColContainer;
    if (pProps.disabled) {
        return pCtx.slots.default!();
    } else {
        return pProps.position === 'left'
            ? h(Container, { class: pProps.class }, () => [
                pCtx.slots.side!(),
                pCtx.slots.default!()
            ])
            : h(Container, { class: pProps.class }, () => [
                pCtx.slots.default!(),
                pCtx.slots.side!()
            ]);
    }
}
(BodyWrapper as any).props = { disabled: Boolean, class: null, is: String, position: String };

</script>

<style scoped>
.side-panel-tabs-container {
    padding-right: 0.25rem!important;
}
</style>